.error-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.error-bg .error-gradient {
  width: 100%;
  height: 100%;
  background-color: #9fbcc8;
  animation: pulse 10s infinite;
}

.error-container[data-code] {
  margin-top: 100px;
  position: relative;
}
.error-container[data-code]::before {
  z-index: 0;
  content: attr(data-code);
  font-size: 10rem;
  color: #e9ecef;
  position: absolute;
  left: -4rem;
  top: -8rem;
}